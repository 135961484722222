import React, { useState, useEffect } from 'react';
import parse, { Element } from "html-react-parser";
import ContactUsForm from './ContactUsForm'
import BannerComponent from '../../common/Banner/BannerComponent'
import Loader from '../../common/Loader/Loader'
import { Helmet } from 'react-helmet';
import { usePageDataStore } from '../../zustand/pageDataStore';
import ContactUsDetails from './ContactUsDetails';
// import settings from '../../../modules/config/settings'
// import localStorageData from '../../../modules/localData/index'

function ContactUs({setNewLoading,newLoading}) {

  const {pageData,GET_PAGE_ID,QUERY_PAGE_ID} = usePageDataStore(state=>state);

  const [loader1,setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [pageId,setPageId] = useState('');
  
  useEffect(() => {
    pageCall();
    // eslint-disable-next-line
  }, []);

  const pageCall = async () => {
      //get a page id from zustand store

    if (pageData?.length < 1) {
      await QUERY_PAGE_ID();
    }
    const Id = GET_PAGE_ID("Contact Us");
    setPageId(Id.pageId)
  }



  return (
    <>
      {/* {[loader1, loader2,loader3].includes(true) && <Loader />} */}
      <Helmet>
        <title>Contact Us - NAM Singapore</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <BannerComponent imageAlt={`contactUsBanner`} pageId={pageId} setLoader1={setLoader1} setNewLoading={setNewLoading}/>
      {newLoading &&(
      <section className="contact-us-sec section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h1>Contact <span className="text-red">Us</span></h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">

            <ContactUsDetails setLoader2={setLoader2} />
            
            <ContactUsForm setLoader3={setLoader3} />

          </div>
        </div>
      </section>
      )}
    </>
  )
}

export default ContactUs