import React,{useState,useEffect} from 'react';
import MultipleBanner from '../../common/Banner/MultipleBanner';
import { useNavigate } from 'react-router-dom'
import AboutUs from './AboutUs/AboutUs';
import WeAre from './WeAre/WeAre';
import Products from './Products/Products';
import CeoDesk from './CeoDesk/CeoDesk';
import NewsAndInsights from './NewsAndInsights/NewsAndInsights';
import OurTeam from './OurTeam/OurTeam';
import Downloads from './Downloads/Downloads';
import Loader from '../../common/Loader/Loader';
import { usePageDataStore } from '../../zustand/pageDataStore'
import { Helmet } from 'react-helmet';
import OurTeamNew from './OurTeam/OurTeamNew';
import NewsAndInisghtsNew from './NewsAndInsights/NewsAndInisghtsNew';
import CeoDeskNew from './CeoDesk/CeoDeskNew';

const Homepage = ({setNewLoading,newLoading}) => {

  const {pageData,GET_PAGE_ID,QUERY_PAGE_ID} = usePageDataStore(state=>state);

  const navigate = useNavigate();

  // const [loader,setLoader] = useState(false);
  const [loader1,setLoader1] = useState(false);
  const [loader2,setLoader2] = useState(false);
  const [loader3,setLoader3] = useState(false);
  const [loader3A,setLoader3A] = useState(false);
  const [loader4,setLoader4] = useState(false);
  const [loader5,setLoader5] = useState(false);
  const [loader6,setLoader6] = useState(false);
  const [loader7,setLoader7] = useState(false);
  const [loader8,setLoader8] = useState(false);
  const [pageId,setPageId] = useState('');

  useEffect(() => {
    getPages();
    // eslint-disable-next-line
  }, [])

  const setToTop = (e, nav) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(`${nav}`);
  }

  const getPages = async () => {
    // setLoader(true);
    // try {
    //     const { data, status } = await getallPagesNames();
    //     const pages = data?.response;
    //     //pages dropdown
    //     if (status === 200) {
    //         if (pages?.status === 1) {
    //             // setPages(pages?.data?.pageMaster);
    //             const reducedData = pages?.data?.pageMaster?.map(({active,isMultipleBanner,show,...rest})=>({...rest})) 
    //             const data = pages?.data?.pageMaster.filter((item) => "Home" === item.pageName)[0];
    //             setPageId(data.pageId);
    //             // localStorage.setItem("PageData",JSON.stringify(reducedData));
    //             // SET_PAGE_DATA(reducedData)
    //             clearData()
    //         } else {
    //             setPageId('')
    //         }
    //     } else {
    //       setPageId('')
    //     }
    //     setLoader(false);

    // } catch (e) {
    //     console.log(e)
    //     setLoader(false);
    // }
    
      //get a page id from zustand store
      if(pageData?.length < 1){
        await QUERY_PAGE_ID();
      }
     const Id = GET_PAGE_ID("Home");
     setPageId(Id.pageId)
}
  return (
    <>
      {/* {([loader1, loader2, loader3, loader3A, loader4, loader5, loader6, loader7, loader8].includes(true)) && <Loader />} */}
      <Helmet>
        <title>Home - NAM Singapore</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
          <MultipleBanner imageAlt={"HomeBanner"} pageId={pageId} setLoader1={setLoader1} setNewLoading={setNewLoading}/>
      {newLoading === true &&(
         <>
      <AboutUs setLoader2={setLoader2} setToTop={setToTop} />
      <WeAre setLoader3={setLoader3} setLoader3A={setLoader3A} />
      <Products setLoader4={setLoader4} />
      <CeoDesk setLoader5={setLoader5} loader5={loader5} />
      <NewsAndInisghtsNew setLoader6={setLoader6} setToTop={setToTop} />
      <OurTeam setLoader7={setLoader7} />
      <Downloads setLoader8={setLoader8} setToTop={setToTop} />
      </>
        )}
    </>
  )
}

export default Homepage
