import React, { useEffect, useState } from 'react'
import parse, { Element } from 'html-react-parser';
import BannerComponent from '../../common/Banner/BannerComponent';
import AboutUsParagraph from './AboutUsParagraph';
import IconSection from './IconSection';
import Loader from '../../common/Loader/Loader';
import { usePageDataStore } from '../../zustand/pageDataStore';
import { Helmet } from 'react-helmet';


const AboutUs = ({setNewLoading,newLoading}) => {

  const {pageData,GET_PAGE_ID,QUERY_PAGE_ID} = usePageDataStore(state=>state);

  const [loader1,setLoader1] = useState(false);
  const [loader2,setLoader2] = useState(false);
  const [loader3,setLoader3] = useState(false);
  const [pageId,setPageId] = useState('');
  const parser = input =>
  parse(input, {
    replace: domNode => {
      if (domNode instanceof Element && domNode.attribs.class === 'remove') {
        return <></>;
      }
    }
  });

  useEffect(() => {
    //get a page id from zustand store
    const pageCall = async () => {
      if (pageData?.length < 1) {
        await QUERY_PAGE_ID();
      }
      const Id = GET_PAGE_ID("About Us");
      setPageId(Id.pageId)
    }
    pageCall();
    // eslint-disable-next-line
  }, [])
  
  return (
    <>
    {/* {[loader1,loader2,loader3].includes(true) && <Loader/> } */}
     
          <Helmet>
         <title>About Us - NAM Singapore</title>
            <meta name="description" content="Helmet application" />
          </Helmet>
        <BannerComponent imageAlt={`AboutUsBanner`} pageId={pageId} setLoader1={setLoader1} setNewLoading={setNewLoading}/>
       {newLoading === true &&(
         <>
        <AboutUsParagraph parser={parser} setLoader2={setLoader2}/>
        <IconSection parser={parser} setLoader3={setLoader3} />
         </>
        )}
     
    </>
  )
}

export default AboutUs
