import React, { useEffect, useState } from 'react'
import MultipleBanner from '../../common/Banner/MultipleBanner'
import {RBIPolicy,Outlook,WeeklyNewsUpdates,WeekChart,NewsArticles,AnnualBudget,FixedIncomeUpdate,Equity,FundUpdate,Events,Operations} from './index'
import { usePageDataStore } from '../../zustand/pageDataStore';
import Loader from '../../common/Loader/Loader';
import parse from 'html-react-parser';
import { getAllSectionNewsAndInsightDetails, getSectionByIdnNewsAndInsightDetails } from '../../../api/NewsAndInsightApi';
import { Helmet } from 'react-helmet';
// import localStorageData from '../../../modules/localData/index'

function NewsAndInsights({setNewLoading,newLoading}) {
  const {pageData,GET_PAGE_ID,QUERY_PAGE_ID} = usePageDataStore(state=>state);
  const [data, setData] = useState([])
  const [pageId, setPageId] = useState('');
  const [newsData, setNewsData] = useState([]);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);

  const parser = input =>
    parse(input, {
      replace: domNode => {
        if (domNode instanceof Element && domNode.attribs.class === 'remove') {
          return <></>;
        }
      }
    });

  useEffect(() => {
        //get a page id from zustand store
    const pageCall = async()=>{
      if(pageData?.length < 1){
        await QUERY_PAGE_ID();
      }
     const Id = GET_PAGE_ID("News & Insights");
     setPageId(Id.pageId)
    }
    pageCall();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getNewsAndInsight()
  }, [newsData])

  useEffect(() => {
    getAllNews()
  }, [])

  const getNewsAndInsight = async()=> {
 
    try {
      setLoader2(true);
      const { data, status } = await getAllSectionNewsAndInsightDetails();
      if (status === 200) {
        if (data.response.status === 1) {
          setData(data.response.data.newsInsightsIconsDetails)
        } else {
          setData([]);
        }
      }
      else {
        setData([]);
      }
      setLoader2(false);

    } catch (e) {
      setLoader2(false);
      console.log(e, "Error in the News & Insights page");
    }
    
  }

  const getAllNews = async () => {
   
    try {
      setLoader3(true);
      const { data, status } = await getSectionByIdnNewsAndInsightDetails(2);
      if (status === 200) {
        if (data.response.status === 1) {
          setNewsData(data.response.data.newsInsightsIconsDetails[0].newsInsightsItems)
        } else {
          setNewsData([]);
        }
      }
      else {
        setNewsData([]);
      }
      setLoader3(false);

    } catch (e) {
      setLoader3(false);
      console.log(e, "Error in the Outlook section in News & Insights page");
    }
    
  }



  return (
    <div>
      {/* {[loader1, loader2, loader3].includes(true)  && <Loader/>} */}
      <MultipleBanner imageAlt={"NewsBanner"} pageId={pageId} setLoader1={setLoader1} setNewLoading={setNewLoading}/>
      
      {data.map(item => {

        const compId = item.newsInsightsMasterId; 

        const components = {
          1: RBIPolicy,
          2: Outlook,
          3: WeeklyNewsUpdates,
          4: WeekChart,
          5: NewsArticles,
          6: AnnualBudget,
          7: FixedIncomeUpdate,
          8: Equity,
          9: FundUpdate,
          10: Events,
          11: Operations,
        }
        const Component = components[compId]
        return (<div key={compId} >
           <Helmet>
            <title>News & Insights - NAM Singapore</title>
            <meta name="description" content="Helmet application" />
          </Helmet>
          {newLoading &&
          <Component parser={parser} id={item.newsInsightsMasterId} title={item.newsInsightsMasterName} description={item.newsInsightsDescription} {...Component} buttonActive={item.buttonActive} buttonName={item.newsInsightsButtonName} buttonCode={item.newsInsightsButtonCode} documnetLink={item.newsInsightsPdfName} buttonLink={item.newsInsightsButtonLink} iconActive={item.iconActive} iconImage={item.newsInsightsIconImageName} backgroundId={item.newsInsightsBackgroundCode} newsData={newsData} />
           }
          </div>)
      
        })}
    </div>
  )
}

export default NewsAndInsights