import React, { useEffect, useState } from 'react'
import { GetAllFooterDisclaimer, GetAllFooterPrivacyPolicy, GetAllRulesRegulations } from '../../api/FooterApi'
import { Link, useNavigate } from 'react-router-dom'
import locationImg from "../../assets/images/location.png";
import { FooterImage } from '../common/Footer/FooterImage';
import Loader from '../common/Loader/Loader';
import { useFooterDataStore } from '../zustand/footerDataStore';
import FooterCopyright from '../sections/Footer/FooterCopyright';
import FooterLocation from '../sections/Footer/FooterLocation';
import FooterContact from '../sections/Footer/FooterContact';
import FooterSocial from '../sections/Footer/FooterSocial';
import FooterDescription from "../sections/Footer/FooterDescription";
import FooterSignatory from '../sections/Footer/FooterSignatory';
import FooterNav from '../sections/Footer/FooterNav';
import FooterBrowserCompatibility from '../sections/Footer/FooterBrowserCompatibility';

const FooterNavMenus = [
  {
    Name: "Home",
    to: "/"
  },
  {
    Name: "About Us",
    to: "/AboutUs"
  },
  {
    Name: "Products",
    to: "/Products"
  },
  {
    Name: "Our Team",
    to: "/OurTeam"
  },
  {
    Name: `News & Insights`,
    to: "/NewsAndInsights"
  },
  {
    Name: "Contact Us",
    to: "/ContactUs"
  },
  {
    Name: "Downloads",
    to: "/Downloads"
  },
]




const Footer = () => {

  const navigate = useNavigate();


  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [loader6, setLoader6] = useState(false);
  const [loader7, setLoader7] = useState(false);
  const [loader8, setLoader8] = useState(false);

  const { locationAddressData, disclaimerActive, setDisclaimerActive, privacyActive, setPrivacyActive, rulesActive, setRulesActive, disclaimerActiveCount, privacyActiveCount, rulesActiveCount, setDisclaimerActiveCount, setPrivacyActiveCount, setRulesActiveCount } = useFooterDataStore(state => state);

  const setToTop = (e, data) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(`${data.to}`);
  }

  useEffect(() => {
    if (disclaimerActiveCount === 0) getFooterDisclaimer();
    if (privacyActiveCount === 0) getFooterPrivacyPolicy();
    if (rulesActiveCount === 0) getFooterRulesRegulations()

  }, [])

  const getFooterDisclaimer = async () => {
    try {
      setLoader6(true);
      const { data, status } = await GetAllFooterDisclaimer();
      if (status === 200) {
        setLoader6(false);
        if (data?.response?.status === 1) {
          setDisclaimerActive(data?.response?.data[0]?.active);
          setDisclaimerActiveCount(1)

        } else {
          setDisclaimerActive(false);
          setDisclaimerActiveCount(1)
        }
      }
      else {
        setDisclaimerActive(false);

      }

    } catch (error) {
      console.log(error);
      setLoader6(false);
    }
  }

  const getFooterPrivacyPolicy = async () => {
    try {
      setLoader7(true);
      const { data, status } = await GetAllFooterPrivacyPolicy();
      if (status === 200) {
        setLoader7(false);
        if (data?.response?.status === 1) {
          setPrivacyActive(data?.response?.data[0]?.active);
          setPrivacyActiveCount(1)

        } else {
          setPrivacyActive(false);
          setPrivacyActiveCount(1)
        }
      }
      else {
        setPrivacyActive(false);

      }

    } catch (error) {
      console.log(error);
      setLoader7(false);
    }
  }

  const getFooterRulesRegulations = async () => {
    try {
      setLoader8(true);
      const { data, status } = await GetAllRulesRegulations();
      if (status === 200) {
        setLoader8(false);
        if (data?.response?.status === 1) {
          setRulesActive(data?.response?.data[0]?.active);
          setRulesActiveCount(1)
        } else {
          setRulesActive(false);
          setRulesActiveCount(1)
        }
      }
      else {
        setRulesActive(false);

      }

    } catch (error) {
      console.log(error);
      setLoader8(false);
    }
  }






  return (
    <>
      {[loader1, loader2, loader3, loader4, loader5, loader6, loader7, loader8].includes(true) && <Loader />}
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-12">
              <div className="footer-column">
                <FooterDescription setLoader1={setLoader1} />
                <div className="footer-address">
                  {
                    locationAddressData.length !== 0 ? <div className="footer-add-1" onClick={() => window.open(locationAddressData[0].contactUsAddressLink, 'blank')}>
                      <FooterImage imageSrc={locationImg} className="img-fluid" imageAlt='location' />
                    </div> : null
                  }
                  <div className="footer-add" style={{ cursor: 'pointer' }}>
                    <FooterLocation setLoader2={setLoader2} />
                    <FooterContact setLoader3={setLoader3} />
                    <FooterSocial setLoader4={setLoader4} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-12"></div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="footer-column useful-link">
                <h4>Site Navigation</h4>
                <FooterNav FooterNavMenus={FooterNavMenus} setToTop={setToTop} />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-column useful-link">
                <ul className="list-unstyled privacy-p">
                  {disclaimerActive ? <li><Link onClick={(e) => setToTop(e, { to: "/Disclaimer" })} >Disclaimer</Link></li> : null}
                  {privacyActive ? <li><Link onClick={(e) => setToTop(e, { to: "/PrivacyPolicy" })}>Privacy Policy</Link></li> : null}
                  {rulesActive ? <li><Link onClick={(e) => setToTop(e, { to: "/RulesAndRegulations" })}>Rules and Regulations</Link></li> : null}
                  <li><Link><FooterBrowserCompatibility /></Link></li>

                </ul>
                <h4>Signatory of:</h4>
                <FooterSignatory setLoader5={setLoader5} />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="mb-0">
                  <FooterCopyright setLoader5={setLoader5} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
