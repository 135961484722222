import './App.css';
import { BrowserRouter } from "react-router-dom";
import Content from './components/content/Content';
import ScrollToTop from './components/content/ScrollToTop';

function App() {
  return (
    <BrowserRouter basename ="/">
      <ScrollToTop/>
      <div className="App">
        <Content />
      </div>
    </BrowserRouter>
  );
}

export default App;
